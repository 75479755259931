import React, { useEffect, useState } from "react";
import { CiHeart } from "react-icons/ci";
import Header from "../Components/Header";
import { Link } from "react-router-dom";
import axios from "axios";

const ForRent = () => {
  const [listings, setListings] = useState([]);
  const [propertyAdTypes, setPropertyAdTypes] = useState([]);
  const [selectedAdType, setSelectedAdType] = useState("");
  const [address, setaddress] = useState("");

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page

  useEffect(() => {
    const fetchPropertyAdTypes = async () => {
      try {
        const response = await axios.get(
          "https://propertybackend.myappsdevelopment.co.in/property/get-propertyadtypes"
        );
        if (response.data.success) {
          setPropertyAdTypes(response.data.data);
        } else {
          console.error("Failed to fetch property ad types");
        }
      } catch (error) {
        console.error("Error fetching property ad types", error);
      }
    };

    fetchPropertyAdTypes();
  }, [selectedAdType]);

  useEffect(() => {
    fetchAllpropertyAd(selectedAdType, address);
  }, [selectedAdType, address]);

  const fetchAllpropertyAd = async (adType, address) => {
    try {
      const baseUrl =
        "https://propertybackend.myappsdevelopment.co.in/property/get-allpropertyad";
      const params = new URLSearchParams({ adType, address });
      const url = `${baseUrl}?${params.toString()}`;

      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.success) {
        setListings(data.data);
      } else {
        console.error("API response indicates failure:", data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const totalPages = Math.ceil(listings.length / itemsPerPage);
  const currentListings = listings.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  return (
    <div>
      <Header />
      <div className="max-w-6xl mx-auto flex justify-between items-center mt-5 mb-8 px-32">
        <input
          type="text"
          placeholder="Country, City, Town Or Area"
          value={address}
          onChange={(e) => setaddress(e.target.value)}
          className="flex p-4 border border-gray-300 rounded-md outline-none w-[500px]"
        />
        <div className="flex justify-between items-center bg-white border rounded-md shadow-sm">
          <div className="flex items-center space-x-12">
            <select
              className="border-gray-300 rounded-md p-2 cursor-pointer"
              value={selectedAdType}
              onChange={(e) => setSelectedAdType(e.target.value)}
            >
              <option value="">Select Property Ad Type</option>
              {propertyAdTypes.map((adType) => (
                <option key={adType._id} value={adType._id}>
                  {adType.propertyAdType}
                </option>
              ))}
            </select>
            <div className="border-l border-gray-300 h-14 mx-4"></div>
          </div>
        </div>
      </div>

      <div className="max-w-2xl mx-auto flex justify-between items-center mt-10 mb-6">
        <span className="text-lg font-semibold">Properties Available</span>
      </div>

      {currentListings.map((property) => (
        <div
          key={property._id}
          className="max-w-2xl mx-auto shadow-lg rounded-lg border border-gray-200 mt-10"
        >
          <div className="mb-4">
            {property.media && property.media.length > 0 ? (
              <img
                src={property.media[0]}
                alt="Property"
                className="w-full h-96 rounded-t-lg" // Fixed height with object-cover
              />
            ) : (
              <p>No Image Available</p>
            )}
          </div>

          <div className="mb-4 flex justify-between items-center mx-3">
            <div>
              {property.propertyDescription && (
                <h3 className="text-sm font-semibold">
                  {property.propertyDescription}
                </h3>
              )}
              {property.address && (
                <p className="text-gray-700">{property.address}</p>
              )}
              <div className="flex">
                {property.bedrooms && (
                  <p className="text-gray-700">{property.bedrooms} Bed</p>
                )}
                {property.bathrooms && (
                  <p className="text-gray-700">{property.bathrooms} Bath</p>
                )}
                {property.floorArea && property.floorAreaUnit && (
                  <p className="text-gray-700">
                    {property.floorArea}{" "}
                    {property.floorAreaUnit.floorAreaUnitName}
                  </p>
                )}
              </div>
              {property.price && (
                <p className="text-gray-700">Price: €{property.price}</p>
              )}
            </div>
          </div>

          <div className="mb-8 text-right mx-3">
            <Link
              to={`/property/${property._id}`}
              className="text-white font-semibold hover:underline border rounded-full p-3 bg-blue-500"
            >
              View Detail
            </Link>
          </div>
        </div>
      ))}

      {/* Pagination Controls */}
      <div className="flex justify-center items-center mt-10 mb-6 space-x-4">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className={`px-4 py-2 text-sm border rounded-lg ${
            currentPage === 1
              ? "cursor-not-allowed bg-gray-300"
              : "bg-blue-500 text-white hover:bg-blue-600"
          }`}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className={`px-4 py-2 text-sm border rounded-lg ${
            currentPage === totalPages
              ? "cursor-not-allowed bg-gray-300"
              : "bg-blue-500 text-white hover:bg-blue-600"
          }`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ForRent;
