import React from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function Headers() {
  const navigate = useNavigate();

  const handleLogout = async () => {
    console.log("logout1");
    try {
      // await axios.get("http://127.0.0.1:2030/auth/logout", {
      //   withCredentials: "true",
      // });

      localStorage.removeItem("token");
      document.cookie =
        "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      navigate("/SignIn");
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  // Check if user is logged in by checking for a token
  const isLoggedIn = !!localStorage.getItem("token");

  return (
    <header className="bg-transparent text-white py-4 px-[200px]">
      <nav className="container mx-auto flex justify-between items-center">
        {/* Left side links */}
        <ul className="flex space-x-6 font-bold flex items-center">
          <li>
            <Link to="/">
              <img src="/logo1.png" alt="daft.ie logo" className="h-14 mr-14" />
            </Link>
          </li>
          <Link to="/buy" className="hover:underline">
            Buy
          </Link>
          <Link to="/Events" className="hover:underline font-semibold">
            Events
          </Link>
        </ul>

        {/* Right side links */}
        <ul className="flex space-x-6">
          {!isLoggedIn ? (
            <li>
              <Link to="/SignIn" className="hover:underline">
                Sign In
              </Link>
            </li>
          ) : (
            <li>
              <button className="hover:underline" onClick={handleLogout}>
                Logout
              </button>
            </li>
          )}
        </ul>
      </nav>
    </header>
  );
}

export default Headers;
